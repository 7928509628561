import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import { Container } from "../components"
import logo from "../images/logo.jpg"
import scroll from "../images/scroll.png"

const IndexPage = ({ data: { allInstaNode } }) => (
  <Layout>
    <div 
      style={{ 
        width: "100%",
        height: "80vh",
        backgroundColor: "#F6F6F6",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <img
        src={logo}
        style={{
          width: "50%",
        }}
      />
      <img
        src={scroll}
        style={{
          width: "30px"
        }}
      />
    </div>
    <div>
      <Container 
        title="Instagram : Parallelparkchi"
        nodes={allInstaNode}
      />
    </div>
  </Layout>
)

export const pageQuery = graphql`
  query IndexQuery {
    allInstaNode(filter: { username: { eq: "38963852042" } }) {
      edges {
        node {
          id
          username
          likes
          caption
          comments
          localFile {
            childImageSharp {
              fluid(quality: 70, maxWidth: 600, maxHeight: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
export default IndexPage
